import React, { useRef } from "react";
import "./Style.css"; // Import the CSS file for styling
import { ReactComponent as DownArrow } from "./down-arrow.svg";

const WebsiteTemplate = () => {
  return (
    <div className="container">
      {/* Header Section */}
      <header className="header">
        <div className="video-container">
          <video autoPlay loop muted>
            <source src="cult_cover_video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="content welcome-message">
            {/* <h5 className="typewriter"> */}
            <p style={{ padding: "35px", lineHeight: "30px" }}>
              Embark on a global odyssey with CULT INTERNATIONAL, as we unite
              the rich musical heritage of India with the dynamic rhythms of the
              UAE. From the cultural tapestry of India to the modern landscapes
              of the UAE, our mission transcends borders, connecting hearts and
              souls through the universal language of music. Join us as we
              traverse continents, creating harmonies that resonate from the
              heart of India to the soul of the UAE...
              <span className="typewriter"> </span>
            </p>
            {/* </h5> */}
          </div>
        </div>
      </header>

      {/* Divider */}
      <div className="divider"></div>
      {/* Vision Section */}
      <section className="vision">
        <div className="content">
          <h2 className="animate__animated animate__fadeInUp">Vision</h2>
          <p
            className="animate__animated animate__fadeInUp"
            style={{ padding: "35px", lineHeight: "30px" }}
          >
            At CULT 17 GLOBAL, our vision is to bridge the gap between music
            fans worldwide and the world’s best artists. We aim to create
            unforgettable experiences driven by imagination and positivity,
            where music and art can inspire transformative moments around every
            corner. We strive to create safe and magical spaces fueled by
            relentless positivity.
          </p>
        </div>
      </section>

      {/* Divider */}
      <div className="divider"></div>

      {/* Services Section */}
      <section className="services animate__animated animate__fadeInUp">
        <div className="content">
          <h2>What We Do</h2>
          <div className="service-cards">
            <div className="service-card">
              <i className="fas fa-microphone-alt"></i>
              <h3>Artist Management & Bookings</h3>
              <p style={{ lineHeight: "30px" }}>
                We specialize in managing and booking artists, ensuring they
                reach their full potential and connect with their audience in
                meaningful ways.
              </p>
            </div>
            <div className="service-card">
              <i className="fas fa-bullseye"></i>
              <h3>Brand Activation</h3>
              <p style={{ lineHeight: "30px" }}>
                Our brand activation services are designed to enhance brand
                visibility and engagement through innovative strategies and
                creative execution.
              </p>
            </div>
            <div className="service-card">
              <i className="fas fa-code"></i>
              <h3>Programming</h3>
              <p style={{ lineHeight: "30px" }}>
                We curate and manage events, ensuring they are meticulously
                planned and executed to deliver memorable experiences.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Divider */}
      <div className="divider"></div>

      {/* Process Section */}
      <section className="process animate__animated animate__fadeInUp">
        <div className="content">
          <h2>Our Process</h2>
          <div className="process-steps">
            <div className="process-step">
              <h3>01. Planning</h3>
              <p style={{ lineHeight: "30px" }}>
                We provide expert advice to shape and enhance your brand.
              </p>
            </div>
            <div className="process-step">
              <h3>02. Activation</h3>
              <p style={{ lineHeight: "30px" }}>
                We oversee every aspect of your project, ensuring seamless
                execution.
              </p>
            </div>
            <div className="process-step">
              <h3>03. Execution</h3>
              <p style={{ lineHeight: "30px" }}>
                Our creative team works tirelessly to bring your vision to life.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Divider */}
      <div className="divider"></div>

      {/* Achievements Section */}
      <section className="achievements animate__animated animate__fadeInUp">
        <div className="content">
          <h2>Our Achievements</h2>
          <div className="achievement-counters">
            <div className="counter">
              <h3>2000+</h3>
              <p>Total Artists Booked</p>
            </div>
            <div className="counter">
              <h3>1000+</h3>
              <p>Total Shows Executed</p>
            </div>
          </div>
        </div>
      </section>

      {/* Divider */}
      <div className="divider"></div>

      {/* About Us Section */}
      <section className="about-us">
        <div className="content">
          <h2 style={{ padding: "35px" }}>About Us</h2>
          <p style={{ lineHeight: "30px", textAlign: "justify" }}>
            CULT 17 GLOBAL is dedicated to connecting Indian music fans with the
            world’s best artists. We create transformative experiences driven by
            imagination and positivity. Our goal is to create safe and magical
            spaces where music and art can inspire and uplift. We believe in the
            power of music to bring people together and create lasting memories.
            Join us as we continue to push the boundaries of what’s possible in
            the world of music and entertainment.
          </p>
        </div>
      </section>

      {/* Footer Section */}
      <footer>
        <div className="contact-us">
          <div className="contact-info" style={{ padding: "10px" }}>
            Contact Us: cult17emc@gmail.com
          </div>
        </div>
        <p>&copy; 2024 Cult International. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default WebsiteTemplate;
